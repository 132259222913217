import React, {Component} from "react";
class Contact extends Component{
    render(){
        
        return(
            <>
              
        <section id="contact" class="section">

        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                <div class="contact-text section-header text-center">
                <div>
                <h2 class="section-title">Get In Touch</h2>
                <div class="desc-text">
                </div>
                </div>
                </div>
                </div>
            </div>
            <div class="row">

                <div class="col-lg-6 col-md-12">
                    
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                <input type="text" class="form-control" id="name" name="name" placeholder="Name"required data-error="Please enter your name" />
                                <div class="help-block with-errors"></div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                <input type="text" placeholder="Subject" id="msg_subject" class="form-control" name="msg_subject" required data-error="Please enter your subject" />
                                <div class="help-block with-errors"></div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                <input type="text" class="form-control" id="email" name="email" placeholder="Email" required data-error="Please enter your Email"/>
                                <div class="help-block with-errors"></div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                <input type="text" placeholder="Budget" id="budget" class="form-control" name="budget" required data-error="Please enter your Budget"/>
                                <div class="help-block with-errors"></div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <textarea class="form-control" id="message" name="message" placeholder="Write Message" rows={4} data-error="Write your message" require />
                                    <div class="help-block with-errors"></div>
                                </div>
                            <div class="submit-button">
                            <button class="btn btn-common" id="submit" type="submit">Submit</button>
                            <div id="msgSubmit" class="h3 hidden"></div>
                            <div class="clearfix"></div>
                            </div>
                            </div>
                        </div>
                    
                </div>
                <div class="col-lg-1">
                </div>
                <div class="col-lg-4 col-md-12">
        
                    <div class="contact-img">
                        <img data-pagespeed-lazy-src="img/bg/co.PNG" class="img-fluid" alt="" src="img/bg/co.PNG"  />
                    </div>
                    <div class="business-item-info">
                        
                        <p>
                            <h6 style={{color:'rgb(128, 0, 128)'}}> 
                                Address: Suit No. 3, Second Floor, Dantata Plaza, No. 3, Independence Way, Beside 	Murtala Square, Kaduna, Kaduna State, Nigeria.
                            </h6>
                                < label style={{color:'rgb(128, 0, 128)'}}>Telephone : </label> <a href="tel:+234 8063445052" style={{color:'rgb(128, 0, 128)'}}>+234 8063445052</a>, <a style={{color:'rgb(128, 0, 128)'}} href="tel:+234 8071721411">+234 8071721411</a><br/>
                                < label style={{color:'rgb(128, 0, 128)'}}> Email: </label><a style={{color:'rgb(128, 0, 128)'}} href = "mailto: info@elfeejir.com">info@elfeejir.com</a><br/>
                                < label style={{color:'rgb(128, 0, 128)'}}>IG: </label><a style={{color:'rgb(128, 0, 128)'}} href = "https://www.instagram.com/el_feejirconcept/">el_feejirconcept</a><br/>
                                < label style={{color:'rgb(128, 0, 128)'}}>Tweeter:</label> < label style={{color:'rgb(128, 0, 128)'}}>@El_jirconcept</label> <br/>
                                < label style={{color:'rgb(128, 0, 128)'}}>FB: </label>< label style={{color:'rgb(128, 0, 128)'}}>El-feejir Concept Ltd</label>
                                
                        </p>
                    <div>

                </div>


                <div class="col-lg-1">
                </div>

                </div>
                
                </div>
                
            </div>
        </div>
        </section> 
            </>
        )
    }
}

export default Contact;