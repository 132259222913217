import React from 'react';
import  Header from './components/Header';
import About from './components/About';
import Services from './components/Services';
import Team from './components/Team';
import Footer from './components/Footer';
import Contact from './components/Contact';


class App extends React.Component{
  

  
  render(){
    return(
      <div>
        
          <Header />
          <About />
          <Services/>
          <Team/>
          <Contact/>
          <Footer/>
        
      </div>
    );
  }
}

export default App;
