import React, {Component} from "react";
class Footer extends Component{
    render(){
        
        return(
            <>
            <footer>

<section id="footer-Content">
<div className="container">

<div className="row">

    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-mb-12">
        <div className="widget">
        <h3 className="block-title">Contact us</h3>
        <div className="business-item-info">
           
                    <p>
                            <h6 style={{color:'white'}}> 
                                Address: Suit No. 3, Second Floor, Dantata Plaza, No. 3, Independence Way, Beside 	Murtala Square, Kaduna, Kaduna State, Nigeria.
                            </h6>
                            <pre>< label style={{color:'white'}}>Telephone : </label><a href="tel:+234 8063445052" style={{color:'white'}}>+234 8063445052</a> , <a style={{color:'white'}} href="tel:+234 8071721411">+234 8071721411</a><br/>
                                < label style={{color:'white'}}>Email: </label><a style={{color:'white'}} href = "mailto: info@elfeejir.com">info@elfeejir.com</a><br/>
                                < label style={{color:'white'}}>IG: </label><a style={{color:'white'}} href = "https://www.instagram.com/el_feejirconcept/">el_feejirconcept</a><br/>
                                < label style={{color:'white'}}>Tweeter:</label> < label style={{color:'white'}}>@El_jirconcept</label> <br/>
                                < label style={{color:'white'}}>FB: </label>< label style={{color:'white'}}>El-feejir Concept Ltd</label></pre>
                                
                        </p>
        <div>
        
        </div>
        
        
        <div className="col-lg-1">
        </div>
        
        </div>
        
        </div>
        </div>



<div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-mb-12">
<div className="widget">
<h3 className="block-title">El-Feejir</h3>
<ul className="menu">
<li><a href="#about"> - About Us</a></li>
<li><a href="#services"> - Services</a></li>
<li><a href="#blog"> - Projects</a></li>
<li><a href="#team"> - Team</a></li>
<li><a href="#contact"> - Contact</a></li>
</ul>
</div>
</div>


<div className="col-lg-5 col-md-6 col-sm-6 col-xs-6 col-mb-12">
<div className="widget">
<h3 className="block-title">Services</h3>
<ul className="menu">
<li><a href="#services"> - AGRIC COMMODITY BUSINESS</a></li>
<li><a href="#services"> - ARCHITECTURAL SERVICES</a></li>
<li><a href="#services"> - ENVIRONMENTAL SERVICES</a></li>
<li><a href="#services"> - CONSTRUCTION AND PROJECT MANAGEMENT </a></li>
<li><a href="#services"> - INFORMATION AND COMMUNICATION TECHNOLOGY</a></li>
<li><a href="#services"> - GENERAL CONTRACT AND SUPPLIES </a></li>
<li><a href="#services"> - SUSTAINABLE INVESTMENT POLIO </a></li>
</ul>
</div>
</div>

{/* <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-mb-12">
    <div className="widget">
    <h3 className="block-title">Future plan</h3>
    <ul className="menu">
    <li><a href="#future"> - Renewable</a></li>
    <li><a href="#future"> - Substainable Real estate</a></li>
    <li><a href="#future"> - Educational Infrastructure</a></li>
    <li><a href="#future"> - Sustainable Building Materials</a></li>
    </ul>
    </div>
    </div> */}




</div>

</div>

<div className="copyright">
<div className="container">

<div className="row">
<div className="col-md-12">
<div className="site-info text-center">
<p>Design by <a href="http://ofusware.com/" rel="nofollow">Ofusware Limited</a></p>
</div>
</div>

</div>

</div>
</div>

</section>

</footer>


<a href="#" className="back-to-top">
<i className="lni lni-chevron-up"></i>
</a>


            </>
        )
    }
}

export default Footer;