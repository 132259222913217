import React, {Component} from "react";
class Services extends Component{
    render(){
        
        return(
            <>
            <section id="services" className="section">
    <div className="container">
        <center><h2 className="section-title">Services We Provide</h2></center>
    <div className="row justify-content-center justify-content-md-start">

        <div className="col-lg-6 col-md-6 col-xs-12 col-sm-10">
        <div className="services-item text-center">
        <div >
            <img src="img/bg/AGRIC.jpg" className="img-fluid"  alt="img"/>
        </div>
        <h4>AGRIC COMMODITY BUSINESS</h4>
        <p>We source and buy best quality cash crops such as Maize, Soya Seed, Padi Rice, Ginger, Hibiscus, Sesame and so forth. We clean and package them to meet up the demands of our local and international customers.       </p>
        </div>
        </div>

        <div className="col-lg-6 col-md-6 col-xs-12 col-sm-10">
            <div className="services-item text-center">
            <div >
                <img src="img/bg/ARCHITECT.jpg" className="img-fluid" alt="" />
            </div>
            <h4>ARCHITECTURAL SERVICES</h4>
            <p>
                We treat every client in a unique way as we believe every client has their unique needs that requires unique design. Our architectural design solutions aim to cater not only for the immediate need of our clients but also for long term impact. Our expertise ranges from architecture, landscape and interior design.       
            </p>
            
            </div>
        </div>

        
            
        <div className="col-lg-6 col-md-6 col-xs-12 col-sm-10">
        <div className="services-item text-center">
        <div >
            <img src="img/bg/ENVIRONMENT.jpg" className="img-fluid"  alt="img"/>
        </div>
        <h4>Environmental Services</h4>
        <p>
            We source and buy best quality cash crops such as Maize, Soya Seed, Padi Rice, Ginger, Hibiscus, Sesame and so forth. We clean and package them to meet up the demands of our local and international customers.
        </p>
    
        </div>
        </div>

        
        <div className="col-lg-6 col-md-6 col-xs-12 col-sm-10">
        <div className="services-item text-center">
        <div >
            <img src="img/bg/CONSTRUCTION.jpg" className="img-fluid" alt="img"/>
        </div>
        <h4>CONSTRUCTION AND PROJECT MANAGEMENT </h4>
        <p>
        We offer an effective planning, analysis, risk management, procurement, execution and monitoring of projects. To ensure the project as whole remains within the defined cost and time frame, through comprehensive overview by our team at all stages.          
        </p>
        
        </div>
        </div>

        <div className="col-lg-6 col-md-6 col-xs-12 col-sm-10">
            <div className="services-item text-center">
            <div >
                <img src="img/bg/General Contract and Supplies.jpg" className="img-fluid" alt="img" />
            </div>
            <h4>GENERAL CONTRACT AND SUPPLIES </h4>
            <p>
                We render supply services of different kind of goods such as building materials, furniture, agricultural produce, printing services, office equipment among others. We ensure the supply of best quality items within the budget of our clients.
            </p>
            </div>
        </div>

        
        <div className="col-lg-6 col-md-6 col-xs-12 col-sm-10">
            <div className="services-item text-center">
            <div >
                <img src="img/bg/ict.jpg" className="img-fluid" alt="" />
            </div>
            <h4>INFORMATION AND COMMUNICATION TECHNOLOGY</h4>
            <p>
                We provide a wide range of ICT services, including: Internet access, Software Development, Video Conferencing, E-Learning, Consulting, Training and Support Services.
            </p>
            
            </div>
        </div>

        <div className="col-lg-6 col-md-6 col-xs-12 col-sm-10">
            <div className="services-item text-center">
            <div >
                <img src="img/bg/SUSTAINABLE.jpg" className="img-fluid" alt="" />
            </div>
            <h4>SUSTAINABLE INVESTMENT POLIO</h4>
            <p>
            We welcome investments in our agric-commodities business infrastructure, by providing you with the opportunity to own agricultural assets and participate in income generation for yourself.  As we firmly believe agric-commodity is stable, safe and attractive long-term investment. 
            </p>
            
            </div>
        </div>
            
    </div>
    </div>
 </section>

            </>
        )
    }
}

export default Services;