import React, {Component} from "react";
import Carousel from 'react-bootstrap/Carousel';
class Header extends Component{
    render(){
        
        return(
            <div >
               <header className="hero-area">
<div className="overlay">
<span></span>
<span></span>
</div>
<div className="navbar-area">
<div className="container">
<div className="row">
<div className="col-lg-12">
<nav className="navbar navbar-expand-lg">
<a className="navbar-brand" href="#">
<img src="img/logoo.jpg" alt="Logo" />
</a>
<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
<span className="toggler-icon"></span>
<span className="toggler-icon"></span>
<span className="toggler-icon"></span>
</button>
<div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
<ul id="nav" className="navbar-nav ml-auto">
<li className="nav-item active">
<a className="page-scroll" href="#home">Home</a>
</li>
<li className="nav-item">
<a className="page-scroll" href="#about">About</a>
</li>
<li className="nav-item">
<a className="page-scroll" href="#services">Services</a>
</li>
<li className="nav-item">
<a className="page-scroll" href="#team">Team</a>
</li>
<li className="nav-item">
<a className="page-scroll" href="#contact">Contact</a>
</li>
<li className="nav-item">
<a className="page-scroll" href="https://hub.elfeejir.com/">login</a>
</li>
</ul>
</div> 
</nav> 
</div>
</div> 
</div> 
</div> 
<div id="home" >
<div className="container-fuild" >
<div className="row" >
<div className="col-lg-12 col-md-12 col-xs-12">
<div className="contents" style={{paddingTop : 100}}>

<Carousel 
autoPlay={true}
interval={3000}

>
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="img/slider/AGRIC.jpg"
          alt="Agric commodity"
        />
        <Carousel.Caption>
          
          
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="img/slider/ARCHITECT.jpg"
          alt="Architectural"
        />
        <Carousel.Caption>
         
          
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="img/slider/CONSTRUCTION.jpg"
          alt="Project and Construction Management"
        />

        <Carousel.Caption>
          
          
        </Carousel.Caption>

      </Carousel.Item>
      

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="img/slider/ENVIRONMENT.jpg"
          alt="Environmental Services"
        />
        <Carousel.Caption>
        
          
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="img/slider/ict.jpg"
          alt="Information and Communication Technology (ICT)"
        />
        <Carousel.Caption>
          
          
        </Carousel.Caption>
        
      </Carousel.Item>
 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="img/slider/SUSTAINABLE.jpg"
          alt="SUSTAINABLE"
        />
        <Carousel.Caption>
         
          
        </Carousel.Caption>
      </Carousel.Item>

      

     

      
    </Carousel>


</div>
</div>
</div>
</div>
</div>
</header>
            </div>
        );
    }
}

export default Header;