import React, {Component} from "react";
import Accordion from 'react-bootstrap/Accordion';
class Team extends Component{
    render(){
        
        return(
            <>
            <section id="team" className="section">
<div className="container">

<div className="row">
<div className="col-lg-12">
<div className="team-text section-header text-center">
<div>
<h2 className="section-title">Team Members</h2>
</div>
</div>
</div>
</div>

<div className="row">
    <div className="col-lg-3 col-md-6 col-xs-12 col-sm-10 mx-auto">
        <div className="single-team">
        <div className="team-thumb">
        <img data-pagespeed-lazy-src="img/team/t1.jpg" className="img-fluid" alt="" src="img/team/t1.jpg"  /> 
        </div>
        <div className="team-details">
        <div className="team-social-icons">
        <ul className="social-list">
        <li><a href="#"><i className="lni lni-facebook-filled"></i></a></li>
        <li><a href="#"><i className="lni lni-twitter-filled"></i></a></li>
        <li><a href="#"><i className="lni lni-google"></i></a></li>
        </ul>
        </div>
        <div className="team-inner text-center" style={{ position: "relative" }}>
        <h5 className="team-title">MAL. HABIB ABBA SANUSI</h5>
        <p> Managing Director/CEO</p>
        <Accordion >
            <Accordion.Item eventKey="0">
                <Accordion.Header><h6>Read About</h6></Accordion.Header>
                <Accordion.Body>
                An entrepreneur and highly trained Architect with over 10 years of demonstrable technical, ﬁnancial and managerial skills. Experienced in managing projects iteratively from ideation to implementation using design thinking. He obtained BSc. and MSc. in Architecture from Ahmadu Bello University, Zaria Nigeria, Masters in Environmental Management from Nigerian Defense Academy, Kaduna, Nigeria. Certification in Environmental Impact Assessment from International Centre for Training and Development, Abu Dhabi, UAE. A Member of Nigerian Institute of Architects and Nigerian Environmental Society of Nigeria. 
            
                </Accordion.Body>
            </Accordion.Item>
        
        </Accordion>
       
        </div>
        </div>
        </div>
        </div>
</div>

<div className="row">

    <div className="col-lg-3 col-md-6 col-xs-12 col-sm-10 mx-auto">
    <div className="single-team">
    <div className="team-thumb">
        <img data-pagespeed-lazy-src="img/team/t2.PNG" className="img-fluid" alt="" src="img/team/t2.PNG"  />
    </div>
    <div className="team-details">
    <div className="team-social-icons">
    <ul className="social-list">
    <li><a href="#"><i className="lni lni-facebook-filled"></i></a></li>
    <li><a href="#"><i className="lni lni-twitter-filled"></i></a></li>
    <li><a href="#"><i className="lni lni-google"></i></a></li>
    </ul>
    </div>
    <div className="team-inner text-center" style={{ position: "relative" }}>
    <h5 className="team-title">NASIR AHMED</h5>
    <p>Chief Operating Officer</p>
    <Accordion >
            <Accordion.Item eventKey="1">
                <Accordion.Header><h6>Read About</h6></Accordion.Header>
                <Accordion.Body>
                He obtained BSc. in Building and MSc in Project Management both from Ahmadu Bello University Zaria, Nigeria. He is an experience project manager for over 10 years. He is a member of Nigerian Institute of Building.   
                </Accordion.Body>
            </Accordion.Item>
        
        </Accordion>
    </div>
    </div>
    </div>
    </div>


    <div className="col-lg-3 col-md-6 col-xs-12 col-sm-10 mx-auto">
    <div className="single-team">
    <div className="team-thumb">
        <img data-pagespeed-lazy-src="img/team/t3.png" className="img-fluid" alt="" src="img/team/t3.png"  />
    </div>
    <div className="team-details">
    <div className="team-social-icons">
    <ul className="social-list">
    <li><a href="#"><i className="lni lni-facebook-filled"></i></a></li>
    <li><a href="#"><i className="lni lni-twitter-filled"></i></a></li>
    <li><a href="#"><i className="lni lni-google"></i></a></li>
    </ul>
    </div>
    <div className="team-inner text-center" style={{ position: "relative" }}>
    <h5 className="team-title">UMAR BASHIR</h5>
    <p>Trade and Logistics Officer</p>
    <Accordion >
            <Accordion.Item eventKey="2">
                <Accordion.Header><h6>Read About</h6></Accordion.Header>
                <Accordion.Body>
                He holds BSc. in Architecture and PGDLA both from Ahmadu Bello University Zaria, Nigeria. Also, Masters in Transport and Logistics from Nigerian Institute of Transport Technology Zaria, Nigeria. He’s an entrepreneur with over 15 years experienced in trade and logistics business. 
                </Accordion.Body>
            </Accordion.Item>
        
        </Accordion>
    </div>
    </div>
    </div>
    </div>


    <div className="col-lg-3 col-md-6 col-xs-12 col-sm-10 mx-auto">
    <div className="single-team">
    <div className="team-thumb">
        <img data-pagespeed-lazy-src="img/team/t4.PNG" className="img-fluid" alt="Zaks" src="img/team/t4.PNG"  />
    </div>
    <div className="team-details">
    <div className="team-social-icons">
    <ul className="social-list">
    <li><a href="#"><i className="lni lni-facebook-filled"></i></a></li>
    <li><a href="#"><i className="lni lni-twitter-filled"></i></a></li>
    <li><a href="#"><i className="lni lni-google"></i></a></li>
    </ul>
    </div>
    <div className="team-inner text-center" style={{ position: "relative" }}>
    <h5 className="team-title">ZAKARIYA ABDULLAHI</h5>
    <p>Data and Technology Officer</p>
    <Accordion >
            <Accordion.Item eventKey="3">
                <Accordion.Header><h6>Read About</h6></Accordion.Header>
                <Accordion.Body>
                A Computer Information Technologist with vast knowledge in creative computing, data science, information management etc, He obtained international Diploma and BSc. in Information Technology from Mahatma Gandhi University India, and MSc in Computer Science from Nile University Abuja, Nigeria.    
                </Accordion.Body>
            </Accordion.Item>
        
        </Accordion>
    </div>
    </div>
    </div>
    </div>

    <div className="col-lg-3 col-md-6 col-xs-12 col-sm-10 mx-auto">
        <div className="single-team">
        <div className="team-thumb">
        <img data-pagespeed-lazy-src="img/team/t5.PNG" className="img-fluid" alt="" src="img/team/t5.PNG"  />
        </div>
        <div className="team-details">
        <div className="team-social-icons">
        <ul className="social-list">
        <li><a href="#"><i className="lni lni-facebook-filled"></i></a></li>
        <li><a href="#"><i className="lni lni-twitter-filled"></i></a></li>
        <li><a href="#"><i className="lni lni-google"></i></a></li>
        </ul>
        </div>
        <div className="team-inner text-center" style={{ position: "relative" }}>
        <h5 className="team-title">USMAN SULAIMAN JIBRIN</h5>
        <p> Finance and Risk Officer</p>
        <Accordion >
            <Accordion.Item eventKey="4">
                <Accordion.Header><h6>Read About</h6></Accordion.Header>
                <Accordion.Body>
                He holds BSc. (HONS) in Quantity Surveyor, MSc. in Project Management from Ahmadu Bello University Zaria, Nigeria. Currently pursuing his PhD in Quantity Surveying research interest in Building Information Modelling. Mr Usman is a corporate member of the Nigerian Institute of Quantity Surveyors, as well as registered member of QRS.
                </Accordion.Body>
            </Accordion.Item>
        
        </Accordion>
        </div>
        </div>
        </div>
    </div>
    <div className="col-lg-3 col-md-6 col-xs-12 col-sm-10 mx-auto">
        <div className="single-team">
        <div className="team-thumb">
        <img data-pagespeed-lazy-src="img/team/t6.png" className="img-fluid" alt="" src="img/team/t6.png"  />
        </div>
        <div className="team-details">
        <div className="team-social-icons">
        <ul className="social-list">
        <li><a href="#"><i className="lni lni-facebook-filled"></i></a></li>
        <li><a href="#"><i className="lni lni-twitter-filled"></i></a></li>
        <li><a href="#"><i className="lni lni-google"></i></a></li>
        </ul>
        </div>
        <div className="team-inner text-center" style={{ position: "relative" }}>
        <h5 className="team-title">UMAR NASIR AMBURSA</h5>
        <p>Legal Adviser</p>
        <Accordion >
            <Accordion.Item eventKey="5">
                <Accordion.Header><h6>Read About</h6></Accordion.Header>
                <Accordion.Body>
                He is a trained lawyer with over 8 years of practise, He obtained LLB from Usman Danfodio University Sokoto, Nigeria. Experienced in contacts law and companied and allied matters.     
                </Accordion.Body>
            </Accordion.Item>
        
        </Accordion>
        </div>
        </div>
        </div>
    </div>

</div>

</div>
</section>
            </>
        )
    }
}

export default Team;