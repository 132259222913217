import React, {Component} from "react";
class About extends Component{
    render(){
        
        return(<>
        <section id="about">
<div className="container">
    <center><h2 className="section-title">ABOUT US </h2></center>
<div className="row">

<div className="col-lg-6 col-md-12 pl-4">
    <br/><br/><br/>
<div className="business-item-info">
<h3>EL-FEEJIR CONCEPTS LTD</h3>
<p>
     was incorporated in October 2018, to explore the African potential and mitigate its economic, environmental and social concerns through sustainable business models toward attaining Sustainable development in Africa. We Aim to build a world-class conglomerate that offers a reliable, Sustainable, friendly environment and cost effective Services with the highest standards of quality, safety performance and customer satisfaction.
</p>

</div>
</div>

<div className="col-lg-6 col-md-12 pl-0 pt-70 pr-5">
    <div className="business-item-img">
    <img data-pagespeed-lazy-src="img/bg/about.jpg" className="img-fluid" alt="" src="img/bg/about.jpg"  />
    </div>
</div>

</div>
</div>
</section>

        </>);
    }
}
export default About;